<template>
  <a-modal :visible="visible" :confirm-loading="confirmLoading" :footer="false" v-if="visible" class="container"
    :closable="false" width="20%">


    <div class="content">

      <div class="text">チャットを終了しますか？ </div>

      <div class="text2">タスクを完了するためには、残り５回、チャットボットとやりとりをする必要がありますが、このままチャットを終了しても良いですか？</div>
      <div class="flex justify-between" style="bottom: 5rem;width: 100%; margin-top: 2rem;">
        <div class="sure" @click="leave">終了する</div>
        <div class="continue" @click="close">キャンセル</div>
      </div>
    </div>


  </a-modal>
</template>
<script>


import { putAction, download, getAction } from '@/request/http.js'
import request from '../../../request/request';

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      count: 5
    }
  },
  mounted() {


  },
  methods: {

    leave() {
      this.$router.replace('/')
    },

    close() {
      this.visible = false
    }

  },
  created() {

  },
  components: {

  }
}
</script>

<style scoped>
.container-tip {
  width: 100vw;
  height: 100vh;
  background: rgb(203 203 203 / 38%);
  position: fixed;
  top: 0;
  left: 0;
}

.container>>>.ant-modal-content {
  background: unset;
  box-shadow: unset;
}

.container>>>.ant-modal-body {
  padding: 0;
}

.content {

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(247, 247, 250, 1);
  padding: 2rem;
  border-radius: 3rem;
}

.text {

  font-weight: 900;
  color: #000000;
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.text2 {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1rem;
}

.sure {
  background: rgba(208, 23, 23, 1);

  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  width: 12rem;
  text-align: center;
  height: 4.2rem;
  line-height: 4.2rem;
}

.continue {
  background: rgba(0, 0, 0, 1);
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  width: 12rem;
  text-align: center;
  height: 4.2rem;
  line-height: 4.2rem;
}
</style>